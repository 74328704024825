<template>
  <div class="junctions-group">
    <div class="junctions-group__image">
      <div
        class="dragscroll junctions-group__image-wrapper"
        :style="{ overflow: isDesktop ? 'hidden' : 'auto' }"
        ref="imageWrapper"
        :class="{ grabbing: imageZoom > 1 }"
      >
        <img :src="group.img" class="img" :alt="group.groupName" v-if="computedChoice === null" />
        <img
          :src="currentItem.img_original"
          class="img"
          :alt="currentItem.alt"
          :style="{ transform: `scale(${imageZoom})` }"
          v-else
        />
      </div>
      <div>
        <Image-controls
          :imageZoom="imageZoom"
          v-if="computedChoice !== null"
          @increaseZoom="increaseZoom"
          @decreaseZoom="decreaseZoom"
          @updateZoom="updateZoom"
          @showLightBox="isLightBoxShow = true"
        />
      </div>
      <ImageLightBox
        @close="close"
        v-if="computedChoice !== null"
        :isShow="isLightBoxShow"
        :url="currentItem.img_original"
      />
    </div>
    <div class="junctions-group__content">
      <div class="junctions-group__content-title">
        {{ $t('message.junctions.group.title') }}
      </div>
      <div class="junctions-group__content-subtitle">
        {{ group.groupName }}
      </div>
      <div class="junctions-group__content-choice">
        <div v-for="(item, i) in group.items" :key="item.id" class="choice-item">
          <input
            type="radio"
            v-model="computedChoice"
            :value="item.id"
            name="group-choicer"
            class="choice-item__input"
            :id="`choice-${i}`"
          />
          <label :for="`choice-${i}`" class="choice-item__label">
            {{ item.name }}
          </label>
        </div>
      </div>
      <SimpleButton
        :color="'red'"
        :reverse="'reverse'"
        :isDisabled="computedChoice === null || choice === currentSelected || choice === null"
        @clickedFromSimpleBtn="addJunctionToSector"
      >
        <template #icon>
          <img src="@/assets/img/icons/arrow--next.svg" alt="→" class="icon" />
        </template>
        {{ $t('message.junctions.group.button') }}
      </SimpleButton>
    </div>
  </div>
</template>

<script>
import { scrollWithZoom } from '@/utils/customFunctions'
import dragscroll from 'dragscroll/dragscroll.js'
import ImageControls from '@/components/smart/uploader/imageControls'
import ImageLightBox from '@/components/smart/uploader/ImageLightBox'
import SimpleButton from '@/components/elements/Dom/Simple-button'
import device from 'current-device'
export default {
  props: {
    group: Object,
    currentSelected: Number
  },
  data: () => ({
    choice: null,
    isDisabled: true,
    imageZoom: 1,
    isLightBoxShow: false,
    isDesktop: ''
  }),
  components: {
    SimpleButton,
    ImageControls,
    ImageLightBox
  },
  watch: {
    imageZoom() {
      if (this.imageZoom > 0) {
        scrollWithZoom(this.$refs.imageWrapper, this.imageZoom)
      }
    }
  },
  methods: {
    addJunctionToSector() {
      this.$emit('itemSelected', this.choice, this.group.id)
    },
    // currentItem() {
    //   return this.group.items.find(p => p.id === this.computedChoice)
    // },
    updateZoom(value) {
      this.imageZoom = value
    },
    increaseZoom() {
      this.imageZoom += 1
    },
    decreaseZoom() {
      this.imageZoom -= 1
    },
    close() {
      this.isLightBoxShow = false
    }
  },
  mounted() {
    dragscroll.reset()
    device.desktop() ? (this.isDesktop = true) : (this.isDesktop = false)
  },
  computed: {
    computedChoice: {
      get() {
        return typeof this.currentSelected !== 'undefined' ? this.currentSelected : this.choice
      },
      set(value) {
        this.choice = value
      }
    },
    currentItem() {
      return this.choice === null
        ? this.group.items.find(p => p.id === this.currentSelected)
        : this.group.items.find(p => p.id === this.choice)
    }
  }
}
</script>

<style scoped lang="sass">
.junctions-group
  display: grid
  +media((grid-template-columns: (320: 1fr, 960: 8fr 1fr rem(440))))
  +media((padding: (320: rem(40) rem(16) 0, 960: rem(35) rem(40) rem(56))))
  +media((margin-bottom: (320: rem(32), 960: 0)))
  width: 92vw
  box-sizing: border-box
  overflow-y: auto
  max-height: 98vh
  +media((max-width: (320: 92vw, 960: rem(1100))))
  &__image
    width: 100%
    .img
      max-width: 100%
      width: 100%
      height: auto
      line-height: 0
      transform-origin: 0 0
    &-wrapper
      line-height: 0
      &.grabbing
        cursor: grab
  &__content
    +media((grid-column-start: (320: 1, 960: 3)))
    &-title, &-subtitle
      font-weight: bold
      +media((padding: (320: rem(12) 0 0, 960: 0 rem(40) rem(24) 0)))
    &-title
      @extend .fs-18
    &-subtitle
      @extend .fs-14
      +media((padding-bottom: (320: rem(18), 960: rem(24))))
    .simple-btn
      +media((font-size: (320: rem(12), 400: rem(14))))
    &-choice
      +media((padding-bottom: (320: rem(24), 960: rem(36))))
      .choice-item
        min-height: rem(48)
        display: flex
        flex-direction: row
        align-items: center
        &:not(:last-child)
          box-shadow: inset 0 rem(-1) 0 #ECECEC
        &__input
          position: absolute
          display: none
          pointer-events: none
          opacity: 0
          &:checked
            + label
              background-color: $red
              padding-left: rem(40)
              background-image: url("~@/assets/img/icons/filter--check.svg")
              font-weight: 700
              color: #fff
        &__label
          min-height: rem(48)
          width: 100%
          cursor: pointer
          display: flex
          flex-direction: row
          align-items: center
          @extend .fs-12
          color: $light-black
          padding: 0 rem(20)
          border-radius: rem(4)
          height: rem(48)
          background-repeat: no-repeat
          background-position: rem(16) center
          transition: .5s
          &:hover
            background-color: $red
            padding-left: rem(40)
            background-image: url("~@/assets/img/icons/filter--check.svg")
            font-weight: 700
            color: #fff
</style>
