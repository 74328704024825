<template>
  <draggable
    v-model="parentLayersList"
    group="layers"
    @start="drag = true"
    @end="drag = false"
    handle=".handle"
    class="single-system__layers"
  >
    <div v-for="(layer, i) in parentLayersList" :key="`layer-${i}`" class="single-layer">
      <div class="single-layer__header single-layer__header--extend">
        <div class="btn-handle handle"></div>
        <div class="single-layer__header-name">
          <div class="text">
            <!--:class="{'text&#45;&#45;disabled': !layer.isEnabled}"-->
            {{ layer.name }}
          </div>
        </div>
        <CircleMenu
          @clickedFromCircleMenu="parentLayerMenuAction($event, i)"
          :setUp="getButtonsSetup(parentLayers.length, i, layer)"
        >
          <template #icon>
            <img src="@/assets/img/icons/layer--parent-menu.svg" alt="" />
          </template>
        </CircleMenu>
      </div>
      <draggable
        :value="layer.items"
        :group="`items-${i}`"
        @start="drag = true"
        @end="drag = false"
        @input="emmiter($event, i)"
        handle=".handle"
        class="single-layer__body"
      >
        <div
          v-for="(item, n) in layer.items"
          :key="`child-layer-${i}-number-${n}-computed-${getCurrentNumber(i, n)}`"
          class="single-layer__body-item single-layer__body-item--extend"
        >
          <div class="btn-handle handle"></div>
          <div class="number">
            <div class="number__text">
              {{ getCurrentNumber(i, n) }}
            </div>
          </div>
          <div class="elements" v-if="item.type === 'layer'">
            <div
              class="elements__left"
              @click="choseNewMaterial(layer.name, item.name, i, n, item.id)"
              :class="{ 'elements__left--full': !isShowInputOrSelect(item.material) }"
            >
              <div class="elements__left-info">
                <div class="name">{{ item.name }}</div>
                <div class="material">{{ item.material.name }}</div>
              </div>
              <div class="chevron">
                <img class="" src="@/assets/img/icons/filter--select.svg" alt="↓" />
              </div>
            </div>
            <Input-or-select
              :item="item"
              v-if="isShowInputOrSelect(item.material)"
              :layerIndex="i"
              :isInCustom="true"
              :itemIndex="n"
            />
          </div>
          <Mount-layer
            v-else-if="item.type === 'mount'"
            :is-in-custom="true"
            :layer-index="i"
            :item-index="n"
            :selected="item.selected"
            :height="item.height"
            :basement="item.basementType"
            :rate="item.mountRate"
          />
          <Single-layer-footer
            :layer-type="item.type"
            :rate="item.materialRate"
            :type="currentJunction.type"
            :tooltip="item.tooltip"
            :isShow="item.type === 'layer' ? item.material.isCalc : false"
            :units="item.type === 'layer' ? item.material.units : null"
            :layer-index="i"
            :item-index="n"
            :mark="item.mark"
            :is-in-custom="true"
            :is-first="getCurrentNumber(i, n) === 1"
          />
          <CircleMenu
            @clickedFromCircleMenu="childLayerMenuAction($event, i, n)"
            :setUp="getButtonsSetup(layer.items.length, n, item)"
          >
            <template #icon>
              <img src="@/assets/img/menu.svg" alt="" />
            </template>
          </CircleMenu>
        </div>
        <Item-add
          :layerIndex="i"
          :layerId="layer.id"
          :placement="'junction'"
          @addMountLayer="addMountLayer"
          @getNewLayer="getNewLayer($event, layer.name, i, layer.items.length)"
        />
      </draggable>
    </div>
    <Layer-add :placement="'junction'" />
    <Modal
      :isModalOpen="isModalOpen"
      :isCloseInHeader="true"
      :isNoRadius="true"
      :can-close="isCanClose"
      @close="closeModal"
    >
      <template #body>
        <LayerProductsView
          :path="layersPath"
          :layerIndex="layerIndexToSelect"
          :itemIndex="itemIndexToSelect"
          :placement="'junction'"
          :response="responseItems"
          @deleteAndClose="deleteAndClose"
          @close="selectProductAndClose"
        />
      </template>
    </Modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </draggable>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { getItemNumber, getButtonsSetup, isShowElement } from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import LayerProductsView from '@/components/smart/layersProducts/ProductSelection'
import MountLayer from '@/components/smart/Junction/MountLayer'
import SingleLayerFooter from '@/components/smart/Junction/SingleLayerFooter'
import CircleMenu from '@/components/elements/Dom/CircleMenu'
import draggable from 'vuedraggable'
import LayerAdd from '@/components/elements/Layers/LayerAdd'
import ItemAdd from '@/components/elements/Layers/ItemAdd'
import InputOrSelect from '@/components/elements/Dom/InputOrSelect'
import { getMaterialsForLayer } from '@/api'

export default {
  data: () => ({
    isModalOpen: false,
    isShowParentLayerAddList: false,
    layerIndexToSelect: '',
    itemIndexToSelect: '',
    isShowCloseInModal: true,
    layersPath: [],
    isLoading: false,
    responseItems: {},
    isCanClose: false
  }),
  components: {
    draggable,
    CircleMenu,
    LayerAdd,
    ItemAdd,
    Modal,
    LayerProductsView,
    InputOrSelect,
    MountLayer,
    SingleLayerFooter
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_JUNCTION_LAYERS_ORDER: 'UPDATE_SECTOR_JUNCTION_LAYERS_ORDER',
      DELETE_SECTOR_JUNCTION_CHILD_LAYER: 'DELETE_SECTOR_JUNCTION_CHILD_LAYER',
      ADD_MOUNT_LAYER_TO_JUNCTION: 'ADD_MOUNT_LAYER_TO_JUNCTION',
      ADD_CHILD_LAYER_TO_JUNCTION: 'ADD_CHILD_LAYER_TO_JUNCTION'
    }),
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    },
    isShowItemAddBtn(layer) {
      const isBaseLayer = Object.prototype.hasOwnProperty.call(layer, 'isBaseLayer')
      return !(isBaseLayer && layer.items.length === 1)
    },
    getNewLayer(newLayer, layer, layerIndex, itemIndex) {
      // console.log(newLayer, layer, layerIndex, itemIndex);
      this.ADD_CHILD_LAYER_TO_JUNCTION({
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        layerIndex: layerIndex,
        layer: newLayer
      })
      this.isLoading = true
      getMaterialsForLayer(this.$i18n.locale, 'junction', null, null, 'custom', newLayer.id).then(
        response => {
          // console.log(response);
          this.isLoading = false
          this.isModalOpen = !this.isModalOpen
          this.layerIndexToSelect = layerIndex
          this.itemIndexToSelect = itemIndex
          this.isShowCloseInModal = false
          this.isCanClose = false
          this.layersPath = [layer, newLayer.name]
          this.responseItems = response.data
          // this.responseItems = this.layersProducts;
        }
      )
    },
    addMountLayer(value) {
      this.ADD_MOUNT_LAYER_TO_JUNCTION({
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        layerIndex: value
      })
    },
    closeModal(val) {
      const isDeleteNewLayer = val?.isDeleteNewLayer || false
      if (isDeleteNewLayer) {
        this.DELETE_SECTOR_JUNCTION_CHILD_LAYER({
          sectorIndex: this.sectorIndex,
          layerIndex: this.layerIndexToSelect,
          itemIndex: this.itemIndexToSelect,
          junctionIndex: this.junctionIndex
        })
      }
      this.isModalOpen = false
    },
    deleteAndClose(args) {
      // this.isModalOpen = false
      this.DELETE_SECTOR_JUNCTION_CHILD_LAYER({
        sectorIndex: this.sectorIndex,
        layerIndex: args.layerIndex,
        itemIndex: args.itemIndex,
        junctionIndex: this.junctionIndex
      })
      this.selectProductAndClose()
    },
    selectProductAndClose() {
      this.isModalOpen = false
    },
    choseNewMaterial(layer, item, layerIndex, itemIndex, layerId) {
      this.isLoading = true
      getMaterialsForLayer(this.$i18n.locale, 'junction', null, null, 'custom', layerId).then(
        response => {
          this.isLoading = false
          this.isModalOpen = !this.isModalOpen
          this.layerIndexToSelect = layerIndex
          this.itemIndexToSelect = itemIndex
          this.isShowCloseInModal = true
          this.isCanClose = true
          this.layersPath = [layer, item]
          this.responseItems = response.data
        }
      )
    },
    getButtonsSetup(itemsLength, index, item) {
      return getButtonsSetup(itemsLength, index, item, 'junction')
    },
    parentLayerMenuAction(action, currentIndex) {
      if (action === 'up') {
        const arr = cloneDeep(this.parentLayers)
        ;[arr[currentIndex], arr[currentIndex - 1]] = [arr[currentIndex - 1], arr[currentIndex]]
        this.$store.commit('UPDATE_SECTOR_JUNCTION_LAYERS_ORDER', {
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          value: arr
        })
      }
      if (action === 'down') {
        const arr = cloneDeep(this.parentLayers)
        ;[arr[currentIndex], arr[currentIndex + 1]] = [arr[currentIndex + 1], arr[currentIndex]]
        this.$store.commit('UPDATE_SECTOR_JUNCTION_LAYERS_ORDER', {
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          value: arr
        })
      }
      if (action === 'delete') {
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList.splice(currentIndex, 1)
        this.parentLayersList = Object.assign([], newParentLayersList)
      }
    },
    childLayerMenuAction(action, parentIndex, itemIndex) {
      if (action === 'up') {
        const arr = cloneDeep(this.parentLayers[parentIndex].items)
        ;[arr[itemIndex], arr[itemIndex - 1]] = [arr[itemIndex - 1], arr[itemIndex]]
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList[parentIndex].items = arr
        this.parentLayersList = Object.assign([], newParentLayersList)
      }
      if (action === 'down') {
        const arr = cloneDeep(this.parentLayers[parentIndex].items)
        ;[arr[itemIndex], arr[itemIndex + 1]] = [arr[itemIndex + 1], arr[itemIndex]]
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList[parentIndex].items = arr
        this.parentLayersList = Object.assign([], newParentLayersList)
      }
      if (action === 'delete') {
        this.DELETE_SECTOR_JUNCTION_CHILD_LAYER({
          sectorIndex: this.sectorIndex,
          layerIndex: parentIndex,
          itemIndex: itemIndex,
          junctionIndex: this.junctionIndex
        })
      }
    },
    emmiter(val, index) {
      // console.log(val);
      const [...newParentLayersList] = this.parentLayersList
      newParentLayersList[index].items = val
      this.parentLayersList = Object.assign([], newParentLayersList)
    },
    isShowInputOrSelect(material) {
      return isShowElement(material)
    }
  },
  computed: {
    ...mapState({
      layersProducts: state => state.layersProducts
    }),
    ...mapGetters({
      getItemsByParent: 'getItemsByParent'
    }),
    parentLayersList: {
      get() {
        return this.parentLayers
      },
      set(value) {
        this.$store.commit('UPDATE_SECTOR_JUNCTION_LAYERS_ORDER', {
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          value: value
        })
      }
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    parentLayers() {
      return this.currentJunction.layers
    }
  }
}
</script>

<style lang="sass" scoped>
// @import "~@/assets/sass/_layers.sass"
.single-system__layers
  .single-layer
    &__header
      &--extend
        +media((grid-template-columns: (320: 1fr rem(40), 768: rem(20) 1fr rem(40))))
    &__body
      &-item
        &--extend
          // &.sortable
            &-chosen, &-ghost
              background: #fff
              opacity: 1!important
          +media((grid-template-columns: (320: 2fr 1fr 22fr rem(32), 768: rem(20) 2fr 1fr 22fr 2fr)))
          .btn-handle--extend
            grid-row: 1 / 3
          .elements, .elements-base
            +media((grid-column: (320: '3 / 4', 768: '3 / 5', 960: '4 / 5')))
          .fastener-info
            grid-column-start: 4
            .fastener-name
              &:before
                left: calc((((100% / 22) * 2) * -1) - 1px)
</style>
