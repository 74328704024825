<template>
  <div class="layer-footer" :class="{ 'layer-footer--custom': isInCustom }">
    <div class="mark">
      <div class="text">
        {{ $t('message.junction.layers.footer.mark') }}
      </div>
      <input type="text" class="input" v-model="computedMark" v-if="isInCustom" />
      <div class="input input--disabled" v-else>{{ mark }}</div>
    </div>
    <div class="rate" v-if="layerType === 'layer' && isShow">
      <tippy interactive placement="right" v-if="tooltip" :maxWidth="181">
        <template #trigger>
          <div class="text text--info">
            {{
              type === 'linear'
                ? `${$t('message.junction.layers.footer.attachLinear')}`
                : `${$t('message.junction.layers.footer.attachChiseled')}`
            }}
            <span class="icon"></span>
          </div>
        </template>
        <span class="fastener-info__attach-tooltip">
          <!--{{ $t('message.junction.layers.footer.attachTooltip') }}-->
          {{ tooltip }}
        </span>
      </tippy>
      <template v-else>
        <div
          class="text"
          v-text="
            type === 'linear'
              ? `${$t('message.junction.layers.footer.attachLinear')}`
              : `${$t('message.junction.layers.footer.attachChiseled')}`
          "
        ></div>
      </template>
      <div
        class="layer--input-with-units"
        :class="{ 'layer--input-with-units--disabled': !isInCustom }"
      >
        <div class="value" v-if="!isInCustom">
          {{ rate }}
        </div>
        <input type="number" v-else min="0" max="1000" step="0.1" v-model.number="computedRate" />
        <span class="units">{{ $t(`message.units.${rateUnits}`) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    rate: Number,
    isFirst: Boolean,
    isInCustom: Boolean,
    layerIndex: Number,
    itemIndex: Number,
    type: String,
    layerType: String,
    isShow: {
      type: Boolean,
      default: false
    },
    units: {
      type: String,
      default: 'mm'
    },
    mark: String,
    tooltip: String
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_LAYER_MATERIAL_PARAM: 'UPDATE_JUNCTION_LAYER_MATERIAL_PARAM'
    })
  },
  computed: {
    rateUnits() {
      return this.units === 'm3' ? 'm2' : this.units
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    computedRate: {
      get() {
        return this.rate
      },
      set(value) {
        if (!isNaN(parseFloat(value)) && value >= 0) {
          this.UPDATE_JUNCTION_LAYER_MATERIAL_PARAM({
            sectorIndex: this.sectorIndex,
            layerIndex: this.layerIndex,
            itemIndex: this.itemIndex,
            junctionIndex: this.junctionIndex,
            param: 'materialRate',
            value: value
          })
        }
      }
    },
    computedMark: {
      get() {
        return this.mark
      },
      set(value) {
        this.UPDATE_JUNCTION_LAYER_MATERIAL_PARAM({
          sectorIndex: this.sectorIndex,
          layerIndex: this.layerIndex,
          itemIndex: this.itemIndex,
          junctionIndex: this.junctionIndex,
          param: 'mark',
          value: value
        })
      }
    }
  }
}
</script>
<style lang="sass">
.tippy-popper[x-placement^=right], .tippy-popper[x-placement^=left], .tippy-popper[x-placement^=bottom], .tippy-popper[x-placement^=top]
  .tippy-backdrop
    border-radius: 0
</style>
<style scoped lang="sass">
.single
  &-layer
    &__body
      &-item
        .layer
          &-footer
            grid-row-start: 2
            grid-column: 3 / 4
            display: grid
            +media((grid-row-gap: (320: rem(12), 960: 0)))
            +media((grid-template-columns: (320: 1fr, 480: 1fr 1fr, 576: 9fr 13fr)))
            padding: rem(12) 0
            box-shadow: inset 0 rem(1) 0 #ECECEC
            &--custom
              +media((grid-column: (320: '3 / 4', 768: '3 / 5', 960: '4 / 5')))
              justify-content: space-between
            .mark, .rate
              display: flex
              flex-direction: row
              align-items: center
            .mark
              .text
                @extend .fs-12
                color: #999999
                +media((margin-right: (320: rem(8), 960: rem(18))))
                max-width: rem(62)
              .input
                @extend .clear-input
                max-width: rem(88)
                height: rem(32)
                border: rem(1) solid #D6D6D6
                box-sizing: border-box
                border-radius: rem(4)
                padding: 0 rem(12)
                @extend .fs-14
                font-weight: bold
                font-family: $main-font
                &--disabled
                  display: flex
                  flex-direction: row
                  align-items: center
                  min-width: rem(80)
                  background: #ECECEC
                  border-color: #ECECEC
                  color: rgba(51, 51, 51, 0.3)
            .rate
              +media((justify-content: (320: flex-start, 480: flex-end)))
              .text
                @extend .fs-12
                color: #999999
                +media((text-align: (320: left, 960: right)))
                +media((margin-right: (320: rem(8), 960: rem(20))))
                +media((max-width: (320: rem(100), 960: rem(140))))
                display: flex
                align-items: center

                &--info
                  cursor: help
                  margin-right: rem(10)
                  +media((max-width: (320: rem(130), 960: rem(140))))
                  .icon
                    margin: 0 0 0 rem(10)
                    height: rem(20)
                    width: rem(20)
                    flex: 0 0 rem(20)
                    outline: none
                    box-shadow: none
                    display: block
                    background-image: url("~@/assets/img/icons/info--default.svg")
                  @media(any-hover: hover)
                    &:hover
                      .icon
                        background-image: url("~@/assets/img/icons/info--active.svg")
              .layer--input-with-units
                height: rem(32)

          &--extend
            .layer-footer
              grid-column: 3 / 5
</style>
